import cn from 'classnames';
import { FieldError } from 'react-hook-form';

interface ErrorMessageProps {
    error: FieldError | undefined;
    className?: string;
    isPadded?: boolean;
}

const ErrorMessage = ({ error, className, isPadded = true }: ErrorMessageProps) => {
    return error ? (
        <>
            {isPadded ? (
                <div className="flex justify-between items-center gap-3">
                    <span className="hidden sm:block w-60" />
                    <span className="hidden sm:block" />
                    <div className={cn('text-brand-red italic text-sm pt-1 w-full', className)}>{error?.message}</div>
                </div>
            ) : (
                <div className={cn('text-brand-red italic text-sm pt-1 w-full', className)}>{error?.message}</div>
            )}
        </>
    ) : null;
};

export default ErrorMessage;
