import { NumberFormatter } from '@internationalized/number';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { format as dateFnsTzFormat, toZonedTime } from 'date-fns-tz';
import { DateFormatter } from 'react-aria';
import { qtyFormatterConfig } from '../config/config';

export const formatNotification = (date: Date) => {
    return format(date, 'hh:mm aaa');
};

export const formatUTCNotification = (date: Date, tz = 'UTC') => {
    return dateFnsTzFormat(toZonedTime(date, tz), 'hh:mm aaa', { timeZone: tz });
};

export const formatLocalTimestamp = (ms: number) => {
    // return dateFnsTzFormat(toZonedTime(ms, tz), 'HH:mm:ss.SSS', { timeZone: tz });
    return format(ms, 'HH:mm:ss');
};

export const formatTimestamp = (ms: number, tz = 'UTC') => {
    // return dateFnsTzFormat(toZonedTime(ms, tz), 'HH:mm:ss.SSS', { timeZone: tz });
    return dateFnsTzFormat(toZonedTime(ms, tz), 'HH:mm:ss', { timeZone: tz });
};

export const formatDateTime = (formatDate: DateFormatter, datetime: number) => {
    return `${formatDate.format(new Date(datetime))} ${formatTimestamp(datetime)}`;
};

export const formatLocalDateTime = (formatDate: DateFormatter, datetime: number) => {
    return `${formatDate.format(new Date(datetime))} ${formatLocalTimestamp(datetime)}`;
};

export const formatPlural = (str: string, num: number) => str + (num > 1 ? 's' : '');

export const formatNumber = (quantity, hundreds, thousands, millions) => {
    if (quantity < 100_000) {
        return hundreds.format(quantity);
    } else if (quantity >= 100_000 && quantity < 1_000_000) {
        return thousands.format(BigNumber(quantity).minus(500).toNumber());
    } else if (quantity >= 1_000_000) {
        return millions.format(BigNumber(quantity).minus(50_000).toNumber());
    }
};

export const formatTraderNumber = (quantity, hundreds, thousands, millions) => {
    if (quantity < 1_000) {
        return hundreds.format(quantity);
    } else if (quantity >= 1_000 && quantity < 100_000) {
        return thousands.format(BigNumber(quantity).minus(5).toNumber());
    } else if (quantity >= 100_000 && quantity < 1_000_000) {
        return thousands.format(BigNumber(quantity).minus(500).toNumber());
    } else if (quantity >= 1_000_000) {
        return millions.format(BigNumber(quantity).minus(50_000).toNumber());
    }
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
export const capitalizeAll = (str: string) => str.split('_').map(capitalize).join(' ');

export const getQtyFormatter = () => {
    /* 
        React aria exposes their number formatter in hook matter, which can be a limitation.
        Use this function when hook is not an option to format the numbers.
        This implementation is an abridged version of react-aria's getDefaultLocale and useNumberFormatter.
        Please lookup in node_modules if updates are needed.
    */
    let locale = (typeof navigator !== 'undefined' && navigator.language) || 'en-US';
    try {
        Intl.DateTimeFormat.supportedLocalesOf([locale]);
    } catch (_err) {
        locale = 'en-US';
    }

    return new NumberFormatter(locale, qtyFormatterConfig);
};
