import { flip, offset as floatingOffset, size, useFloating, useHover, useInteractions } from '@floating-ui/react';
import cn from 'classnames';
import { Fragment, ReactElement, useMemo, useState } from 'react';

interface TooltipProps {
    content?: string;
    className?: string;
    containerClassName?: string;
    offset?: number;
    width?: number;
    placement?:
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'left'
        | 'left-start'
        | 'left-end';
    children: ReactElement;
}

const Tooltip = (props: TooltipProps) => {
    const { content, className, containerClassName, offset = 8, width, placement = 'top-end', children } = props;

    const [isOpen, setIsOpen] = useState(false);

    const middleware = useMemo(() => {
        return [
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: width || `${rects.reference.width * 0.9}px`
                    });
                }
            }),
            floatingOffset(offset),
            flip({ crossAxis: false })
        ];
    }, []);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        strategy: 'fixed',
        placement,
        middleware
    });

    const hover = useHover(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
        <Fragment>
            <div ref={refs.setReference} {...getReferenceProps()} className={cn('', containerClassName)}>
                {children}
            </div>

            {content && isOpen && (
                <span
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className={cn(`z-20 rounded bg-white p-2 text-xs text-neutral-900`, className)}
                    {...getFloatingProps()}>
                    {content}
                </span>
            )}
        </Fragment>
    );
};

export default Tooltip;
