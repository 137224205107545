import { ChangeEventHandler, ComponentProps, ReactElement, cloneElement, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

interface InputControllerProps extends ComponentProps<'input'> {
    name?: string;
    label?: string;
    className?: any;
    onChangeOverride?: ChangeEventHandler<HTMLInputElement>;
    children: ReactElement;
}

const InputController = forwardRef<HTMLInputElement, InputControllerProps>((props, ref) => {
    const { name = '', label, children, ...rest } = props;
    const { required, onChangeOverride, disabled } = rest;
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState: { isSubmitted, errors, defaultValues }
            }) => {
                return (
                    <div className="w-full">
                        <div className="flex flex-col sm:flex-row gap-0 sm:gap-3 justify-between items-center text-neutral-200 w-full">
                            {label ? (
                                <label className="flex justify-between relative text-sm w-full sm:w-60">
                                    {label}
                                    <span className="hidden sm:block space-x-1.5">
                                        {required && <sup className=" text-sm -top-1">*</sup>}
                                        <span>:</span>
                                    </span>
                                </label>
                            ) : null}
                            <div className="w-full">
                                {cloneElement(children, {
                                    ref,
                                    name,
                                    value,
                                    onBlur,
                                    onChange: (e) => {
                                        onChange(e);
                                        if (onChangeOverride) onChangeOverride(e);
                                    },
                                    selected: value,
                                    defaultValue: defaultValues && defaultValues[name],
                                    className: {
                                        'text-neutral-400 cursor-not-allowed': disabled,
                                        '': !disabled
                                    },
                                    ...rest
                                })}
                            </div>
                        </div>
                        <ErrorMessage error={error} />
                    </div>
                );
            }}
        />
    );
});

InputController.displayName = 'InputController';

export default InputController;
